.vehicleCard {
  border-radius: 6px;
  border: 1px solid #00000033;
  box-shadow: 2px 4px 7px 0px #00000033;
  overflow: hidden;
}

.desktopCardGridContainer {
  display: grid;
  grid-template-columns: 2fr 2fr 1.2fr;
  grid-template-rows: 1.2fr 0.1fr 0.1fr;
  gap: 0px 0px;
  grid-template-areas:
    'vehicleImage cardDetailsGrid-A cardDetailsGrid-B'
    'buttonsContainer buttonsContainer buttonsContainer'
    'cardFooter cardFooter cardFooter';
  height: 100%;
}

.cardDetailsGrid-A {
  grid-area: cardDetailsGrid-A;
  padding: 24px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardDetailsGrid-B {
  grid-area: cardDetailsGrid-B;
  padding: 12px 12px 8px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.desktopCardImageGridContainer {
  grid-area: vehicleImage;
}

.cardFooter {
  grid-area: cardFooter;
  border-top: 1px solid #979aa6;
  padding: 10px;
  display: flex;
  gap: 12px;
  align-items: flex-end;
}

.vehicleImageContainer {
  position: relative;
  display: flex;
  width: 100%;
  gap: 2px;
  overflow: hidden;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.mobileVehicleCardContentContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  position: relative;
}

.cardDetailsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mobilePriceStarRatingContainer {
  position: relative;
}

.mobileStarRatingContainer {
  position: absolute;
  right: 0;
  top: -12px;
}
.starRatingContainer {
  display: block;
}

.questionIcon {
  margin-left: auto;
  width: 12px;
}

.price {
  font-family: 'Montserrat';
  font-size: 18px;
  color: #64bc46;
  padding-top: 12px;
}

.vehicleIdentifier {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
}

.makeModel {
  font-family: 'Montserrat';
  color: #000000;
  font-size: 14px;
}
.derivative {
  font-weight: 400;
  font-size: 12px;
}

.vehicleStats {
  font-size: 11px;
  line-height: 15.4px;
  font-weight: 700;
}
.mobileVehicleMetricContainer {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.vehicleMetricContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  text-align: right;
  margin-top: 10px;
}

.vehicleSource {
  font-size: 11px;
}

// Buttons
.buttonsContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  gap: 16px;
  padding: 8px;
}
.buttonsContainer {
  grid-area: buttonsContainer;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row-reverse;
  align-content: center;
  width: 100%;
  gap: 16px;
  padding: 8px;
}

.quoteButton {
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 18px;
  min-height: 40px;
  width: 100%;
}

.secondaryButton {
  font-family: 'Open Sans';
  border-radius: 4px;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  background: white;
  text-align: center;
  min-width: unset;

  :global(.button__spinner) {
    border-color: inherit;
  }
}

.iconButtonWrapper {
  display: flex;
  justify-items: center;
  align-items: center;
}

.sendDealIcon {
  font-size: 32px;
  color: inherit;
}

.amendText {
  font-size: 18px;
}
