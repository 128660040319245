.advertisedOn {
  display: flex;
  height: 16px;
  gap: 8px;
  font-size: 11px;
}

.advertisedOnLogo {
  width: 65px;
}
